<template>
  <v-container>
    <v-card outlined class="mb-8 my-sm-16">
      <v-card-title class="justify-center primary--text text-bold">Privacy and Cookies Policy</v-card-title>
      <v-card-text>
        <p>
          We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat
          your personal information.
        </p>
        <p>This policy is divided into three parts:</p>
        <p>
          <ul>
            <li>Part A provides information about our collection and use of personal information</li>
            <li>Part B provides information about the cookies used on this website</li>
            <li>Part C contains information about us (including contact details).</li>
          </ul>
          <br/>
          <!-- Part A provides information about our collection and use of personal information<br />
          Part B provides information about the cookies used on this website<br />
          Part C contains information about us (including contact details).<br /> -->
          We will ask you to consent to our use of cookies in accordance with the terms of this policy when you first
          visit our website. By using our website and agreeing to this policy, you consent to our use of cookies in
          accordance with the terms of this policy.<br />
        </p>
        <v-card-title class="px-0 primary--text">Part A: Your Personal Information</v-card-title>
        <v-card-subtitle class="px-0 primary--text">(1) What information do we collect?</v-card-subtitle>
        <p>We may collect, store and use the following kinds of personal information:</p>
        <p>
          (a) information about your computer and about your visits to and use of this website (including your IP
          address, geographical location, browser type and version, operating system, referral source, length of visit,
          page views, website navigation and details);
        </p>
        <p>
          (b) information relating to any transactions carried out between you and us on or in relation to this website,
          including information relating to any purchases you make of our goods or services (including details);
        </p>
        <p>(c) information that you provide to us for the purpose of registering with us (including details);</p>
        <p>
          (d) information that you provide to us for the purpose of subscribing to our website services, email
          notifications and/or newsletters (including details);
        </p>
        <p>(e) any other information that you choose to send to us; and</p>
        <p>(f) other information.</p>
        <p>
          Before you disclose to us the personal information of another person, you must obtain that person’s consent to
          both the disclosure and the processing of that personal information in accordance with the terms of this
          policy.
        </p>
        <v-card-subtitle class="px-0 primary--text">(2) Using your personal information</v-card-subtitle>
        <p>
          Personal information submitted to us via this website will be used for the purposes specified in this policy
          or in relevant parts of the website.
        </p>
        <p>We may use your personal information to:</p>
        <p>(a) administer the website;</p>
        <p>(b) improve your browsing experience by personalising the website;</p>
        <p>(c) enable your use of the services available on the website;</p>
        <p>(d) send you goods purchased via the website, and supply to you services purchased via the website;</p>
        <p>(e) send statements and invoices to you, and collect payments from you;</p>
        <p>(f) send you general (non-marketing) commercial communications;</p>
        <p>(g) send you email notifications which you have specifically requested;</p>
        <p>
          (h) send you our newsletter and other marketing communications relating to our business or the businesses of
          carefully-selected third parties which we think may be of interest to you, by post or, where you have
          specifically agreed to this, by email or similar technology (and you can inform us at any time if you no
          longer require marketing communications);
        </p>
        <p>
          (i) provide third parties with statistical information about our users – but this information will not be used
          to identify any individual user;
        </p>
        <p>(j) deal with enquiries and complaints made by or about you relating to the website;</p>
        <p>(k) keep the website secure and prevent fraud;</p>
        <p>
          (l) verify compliance with the terms and conditions governing the use of the website (including monitoring
          private messages sent through our website private messaging service); and
        </p>
        <p>(m) other uses.</p>
        <p>
          Where you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the licence you grant to us.
        </p>
        <p>Your privacy settings can be used to limit the publication of your information on the website.</p>
        <p>
          Information  on how to adjust your privacy settings for the following browsers is detailed in the links below:-
          <br/> <a href="https://support.google.com/chrome/answer/114836?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Chrome</a>
          <br/> <a href="https://support.apple.com/en-in/guide/safari/sfri35610/mac#:~:text=In%20the%20Safari%20app%20on,%3E%20Preferences%2C%20then%20click%20Privacy" target="_blank">Safari</a>
          <br/> <a href="https://support.mozilla.org/en-US/kb/settings-privacy-browsing-history-do-not-track#firefox:mac:fx38" target="_blank">Firefox</a>
          <br/> <a href="https://help.opera.com/en/latest/security-and-privacy/" target="_blank">Opera</a> 
        </p>
        <p>
          We will not, without your express consent, provide your personal information to any third parties for the
          purpose of direct marketing.
        </p>
        <p>
          All our website financial transactions are handled through our payment services provider, Stripe. You can
          review the Stripe privacy policy at https://stripe.com/en-gb/privacy. We will share information with Stripe only to the extent
          necessary for the purposes of processing payments you make via our website, refunding such payments and
          dealing with complaints and queries relating to such payments and refunds.
        </p>
        <v-card-subtitle class="px-0 primary--text">(3) Disclosures</v-card-subtitle>
        <p>
          We may disclose your personal information to any of our employees, officers, agents, suppliers or
          subcontractors insofar as reasonably necessary for the purposes set out in this policy.
        </p>
        <p>
          We may disclose your personal information to any member of our group of companies (this means our
          subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the
          purposes set out in this policy.
        </p>
        <p>In addition, we may disclose your personal information:</p>
        <p>(a) to the extent that we are required to do so by law;</p>
        <p>(b) in connection with any ongoing or prospective legal proceedings;</p>
        <p>
          (c) in order to establish, exercise or defend our legal rights (including providing information to others for
          the purposes of fraud prevention and reducing credit risk);
        </p>
        <p>
          (d) to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating)
          selling; and
        </p>
        <p>
          (e) to any person who we reasonably believe may apply to a court or other competent authority for disclosure
          of that personal information where, in our reasonable opinion, such court or authority would be reasonably
          likely to order disclosure of that personal information.
        </p>
        <p>Except as provided in this policy, we will not provide your information to third parties.</p>
        <v-card-subtitle class="px-0 primary--text">(4) International data transfers</v-card-subtitle>
        <p>
          Information that we collect may be stored and processed in and transferred between any of the countries in
          which we operate in order to enable us to use the information in accordance with this policy.
        </p>
        <p>
          Information which you provide may be transferred to countries (including the United States, Japan, other
          countries) which do not have data protection laws equivalent to those in force in the European Economic Area.
        </p>
        <p>
          In addition, personal information that you submit for publication on the website will be published on the
          internet and may be available, via the internet, around the world. We cannot prevent the use or misuse of such
          information by others.
        </p>
        <p>You expressly agree to such transfers of personal information.</p>
        <v-card-subtitle class="px-0 primary--text">(5) Security of your personal information</v-card-subtitle>
        <p>
          We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of
          your personal information.
        </p>
        <p>
          We will store all the personal information you provide on our secure (password- and firewall-protected)
          servers.
        </p>
        <p>All electronic transactions entered into via the website will be protected by encryption technology.</p>
        <p>
          You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot
          guarantee the security of data sent over the internet.
        </p>
        <p>
          You are responsible for keeping your password and other login details confidential. We will not ask you for
          your password (except when you log in to the website).
        </p>
        <v-card-subtitle class="px-0 primary--text">(6) Policy amendments</v-card-subtitle>
        <p>
          We may update this policy from time to time by posting a new version on our website. You should check this
          page occasionally to ensure you are happy with any changes.
        </p>
        <p>We may also notify you of changes to our privacy policy by email.</p>
        <v-card-subtitle class="px-0 primary--text">(7) Your rights</v-card-subtitle>
        <p>
          You may instruct us to provide you with any personal information we hold about you. Provision of such
          information will be subject to:
        </p>
        <p>(a) the payment of a fee (currently fixed at GBP 10); and</p>
        <p>
          (b) the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy
          of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current
          address).
        </p>
        <p>We may withhold such personal information to the extent permitted by law.</p>
        <p>
          You may instruct us not to process your personal information for marketing purposes, by sending an email to
          us. In practice, you will usually either expressly agree in advance to our use of your personal information
          for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal
          information for marketing purposes.
        </p>
        <v-card-subtitle class="px-0 primary--text">(8) Third party websites</v-card-subtitle>
        <p>
          The website contains links to other websites. We are not responsible for the privacy policies or practices of
          third party websites.
        </p>
        <v-card-subtitle class="px-0 primary--text">(9) Updating information</v-card-subtitle>
        <p>Please let us know if the personal information which we hold about you needs to be corrected or updated.</p>
        <v-card-title id="dv_cookies" class="px-0 primary--text">Part B: Cookies</v-card-title>
        <v-card-subtitle class="px-0 primary--text">(10) About cookies</v-card-subtitle>
        <p>
          A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to
          a web browser and is stored by the browser. The identifier is then sent back to the server each time the
          browser requests a page from the server.
        </p>
        <p>
          Cookies can be used by web servers to identity and track users as they navigate different pages on a website
          and identify users returning to a website.
        </p>
        <p>Cookies may be either “persistent” cookies or “session” cookies.</p>
        <p>
          A persistent cookie consists of a text file sent by a web server to a web browser, which will be stored by the
          browser and will remain valid until its set expiry date (unless deleted by the user before the expiry date).
        </p>
        <p>
          A session cookie, on the other hand, will expire at the end of the user session, when the web browser is
          closed.
        </p>
        <v-card-subtitle class="px-0 primary--text">(11) cookies</v-card-subtitle>
        <p>We may use both session cookies and persistent cookies on this website.</p>
        <p>We will/may send to you the following cookies:</p>
        <p>(a) connect.sid(System) – This cookie is used for authentication and for secure log-in. It registers the log-in information.</p>
        <p>(b) _ga(Analytics) – This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.</p>
        <p>(c) _gid(Analytics) – This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected including the number visitors, the source where they have come from, and the pages visted in an anonymous form.</p>
        <p>(d) _gat(Performance) – This cookies is installed by Google Universal Analytics to throttle the request rate to limit the colllection of data on high traffic sites.</p>
        <p>(e) __stripe_mid(Stripe) – This cookie is set by Stripe payment gateway. This cookie is used to enable payment on the website without storing any patment information on a server.</p>
        <p>(f) __stripe_sid(Stripe) – This cookie is set by Stripe payment gateway. This cookie is used to enable payment on the website without storing any patment information on a server.</p>
        <p>(g) m(Stripe) – This cookie is set by Stripe payment gateway. This cookie is used to enable payment on the website without storing any patment information on a server.</p>
        <p>(h) NID – The NID cookie contains a unique ID which has been used by Google to remember your preferences and other information, such as your preferred language (e.g. English), search results shown per page (e.g. 10 or 20), and Google's SafeSearch filter Setting (On/Off).</p>
        <p>(i) DV – This cookie is used to save the user's preferences and other information. This includes in particular the preferred language, the number of search results to be displayed on the page as well as the Google SafeSearch filter option (On/Off). Lifespan: 7 minutes.</p>
        <p>(j) 1P_JAR – This group sets a unique ID to remember your preferences and other information such as website statistics and track conversion rates.</p>
        <v-card-subtitle class="px-0 primary--text">(12) Third party and analytics cookies</v-card-subtitle>
        <p>When you use our website, you may also be sent third party cookies.</p>
        <p>
          Our advertisers / service providers may send you cookies. They may use the information they obtain from your
          use of their cookies:
        </p>
        <p>(a) to track your browser across multiple websites;</p>
        <p>(b) to build a profile of your web surfing; and</p>
        <p>(c) to target advertisements which may be of particular interest to you.</p>
        <p>
          In addition, we use Google Analytics to analyse the use of this website. Google Analytics generates
          statistical and other information about website use by means of cookies, which are stored on users’ computers.
          The information generated relating to our website is used to create reports about the use of the website.
          Google will store this information. Google’s privacy policy is available at:
          http://www.google.com/privacypolicy.html.
        </p>
        <p>
          We publish Google AdSense interest-based advertisements on our website. These are tailored by Google to
          reflect your interests. To determine your interests, Google will track your behaviour on our website and on
          other websites across the web using the DART cookie. You can view, delete or add interest categories
          associated with your browser using Google’s Ads Preference Manager, available at:
          http://www.google.com/ads/preferences/. You can opt out of the AdSense partner network cookie at:
          http://www.google.com/privacy/ads/ or by using the NAI’s (Network Advertising Initiative’s) multi-cookie
          opt-out mechanism at: http://www.networkadvertising.org/managing/opt_out.asp. However, these opt-out
          mechanisms use cookies, and if you clear the cookies from your browser your opt-out will not be maintained. To
          ensure that an opt-out is maintained in respect of a particular browser, you should use the Google browser
          plug-in available at: http://www.google.com/ads/preferences/plugin.
        </p>
        <v-card-subtitle class="px-0 primary--text">(13) Cookies and personal information</v-card-subtitle>
        <p>
          Cookies do not contain any information that personally identifies you, but personal information that we store
          about you may be linked, by us, to the information stored in and obtained from cookies.
        </p>
        <v-card-subtitle class="px-0 primary--text">(14) Blocking cookies</v-card-subtitle>
        <p>Most browsers allow you to refuse to accept cookies. For example:</p>
        <p>
          (a) in Internet Explorer (version 9) you can block cookies using the cookie handling override settings
          available by clicking “Tools”, “Internet Options”, “Privacy” and then “Advanced”;
        </p>
        <p>
          (b) in Firefox (version 16) you can block all cookies by clicking “Tools”, “Options”, “Privacy”, selecting
          “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and
        </p>
        <p>
          (c) in Chrome (version 23), you can delete all cookies by accessing the “Customise and control” menu, and
          clicking “Settings”, “Show advanced settings” and “Clear browsing data”, and then selecting “Delete cookies
          and other site and plug-in data” before clicking “Clear browsing data”.
        </p>
        <p>Again, doing this may have a negative impact on the usability of many websites.</p>
        <v-card-subtitle class="px-0 primary--text">(16) Cookie preferences</v-card-subtitle>
        <p>You can manage your preferences relating to the use of cookies on our website by visiting:</p>
        <a href="https://support.google.com/chrome/answer/114836?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Chrome</a>
        <br/> <a href="https://support.apple.com/en-in/guide/safari/sfri35610/mac#:~:text=In%20the%20Safari%20app%20on,%3E%20Preferences%2C%20then%20click%20Privacy" target="_blank">Safari</a>
        <br/> <a href="https://support.mozilla.org/en-US/kb/settings-privacy-browsing-history-do-not-track#firefox:mac:fx38" target="_blank">Firefox</a>
        <br/> <a href="https://help.opera.com/en/latest/security-and-privacy/" target="_blank">Opera</a> 

        <v-card-title class="px-0 primary--text">Part C: Our Details</v-card-title>
        <v-card-subtitle class="px-0 primary--text">(17) Contact us</v-card-subtitle>
        <p>This website is owned and operated by Ticket Text Limited.</p>
        <p>If you have any questions about our cookies or this policy, please contact us:</p>
        <p>(a) by email <a href="mailto:contact@tickettext.co.uk" target="_blank">contact@tickettext.co.uk;</a></p>
        <p>(b) by telephone 020 3667 3334; or</p>
        <p>(c) by post Ticket Text Limited, 2 Northdown Street, King's Cross, London N1 9BG.</p>
        <p>
          TicketText is a trading name of Ticket Text Limited who’s registered address is 2 Northdown Street, King's Cross, London N1 9BG.
        </p>
        <p>Ticket Text Limited with company number 08294030 is registered in England and Wales.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  mounted() {
    if(this.$route.query && this.$route.query.q && this.$route.query.q === 'cookie') {
      this.$scrollToElement('dv_cookies');
    }
  }
};
</script>
